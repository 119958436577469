<template>
  <div>
    <Preloader />
    <Menu />
    <div class="benefits">
      <p class="benefits__title">
        POLÍTICA DE PRIVACIDAD
      </p>
      <div class="benefits__text">
        <p>
          Esta política de privacidad rige para todas las aplicaciones móviles
          para Android creadas por Appears Development Team y publicadas en la
          cuenta de desarrollador con el nombre antes mencionado.
        </p>
        <p>
          APPEARS S.A.S propietaria del aplicativo QINSPECTING, dando
          cumplimiento estricto a lo establecido en la Ley 1581 de 2012 y en el
          Decreto 1377 de 2013. Establece la presente política para la
          recolección, manejo, uso, tratamiento, almacenamiento e intercambio de
          todas aquellas actividades que constituyan tratamiento de datos
          personales.
        </p>
        <p>
          APPEARS S.A.S, identificado con Nit. 901.377.198-6, con sede principal
          en la calle 24 No. 1-121 Villavicencio, Departamento del Meta en
          Colombia, portal web www.qinspecting.com, www.appears.com.co, Correo
          electrónico gerencia@appears.com.co, teléfono (+57) 3137840166;
          desarrollador y propietaria del aplicativo QINSPECTING Y como
          responsable del tratamiento de sus datos personales, para el
          desarrollo de sus actividades propias, así como para el
          fortalecimiento de sus relaciones con terceros.
        </p>
        <p><b>¿Qué tipo de información recolectamos?</b></p>
        <p>
          Para el correcto funcionamiento de las aplicaciones que construimos o
          desarrollamos, estos son algunos de los datos que recolectamos:
        </p>
        <p><b>Ubicación</b></p>
        Para el caso de las aplicaciones que sea necesario accedemos a los datos
        proporcionados por el GPS. Estos datos no los manejamos nosotros, son
        directamente manejado por Google Maps (nuestro proveedor de mapas). Dato
        de registro Quiero informarle que cada vez que utiliza mi Servicio, en
        caso de un error en la aplicación, recopilo datos e información (a
        través de productos de terceros) en su teléfono llamados
        <p><b>Datos de registro.</b></p>
        Estos datos de registro pueden incluir información como la dirección del
        Protocolo de Internet («IP») de su dispositivo, el nombre del
        dispositivo, la versión del sistema operativo, la configuración de la
        aplicación cuando utiliza mi Servicio, la hora y la fecha de uso del
        Servicio y otras estadísticas.
        <p><b>Cookies</b></p>
        <p>
          Las cookies son archivos con una pequeña cantidad de datos que se usan
          comúnmente como identificadores únicos anónimos. Estos se envían a su
          navegador desde los sitios web que visita y se almacenan en la memoria
          interna de su dispositivo.
        </p>
        <p>
          Este servicio no utiliza estas «cookies» explícitamente. Sin embargo,
          la aplicación puede usar código de terceros y bibliotecas que usan
          «cookies» para recopilar información y mejorar sus servicios. Tiene la
          opción de aceptar o rechazar estas cookies y saber cuándo se envía una
          cookie a su dispositivo. Si elige rechazar nuestras cookies, es
          posible que no pueda usar algunas partes de este Servicio.
        </p>
        <p><b>Proveedores de servicio</b></p>
        Puedo emplear a empresas e individuos de terceros debido a las
        siguientes razones:
        <ul>
          <li>Para facilitar nuestro servicio;</li>
          <li>
            Para proporcionar el Servicio en nuestro nombre
          </li>
          <li>
            Para realizar servicios relacionados con el Servicio
          </li>
          <li>Para ayudarnos a analizar cómo se utiliza nuestro Servicio</li>
        </ul>
        <p>
          Deseo informar a los usuarios de este Servicio que estos terceros
          tienen acceso a su Información personal. La razón es realizar las
          tareas que se les asignaron en nuestro nombre. Sin embargo, están
          obligados a no divulgar ni utilizar la información para ningún otro
          propósito.
        </p>
        <p><b>Seguridad</b></p>
        <p>
          Valoro su confianza en proporcionarnos su información personal, por lo
          tanto, nos esforzamos por utilizar medios comercialmente aceptables
          para protegerla. Pero recuerde que ningún método de transmisión por
          Internet o método de almacenamiento electrónico es 100% seguro y
          confiable, y no puedo garantizar su seguridad absoluta.
        </p>
        <p><b>Enlaces a otros sitios</b></p>
        <p>
          Este servicio puede contener enlaces a otros sitios. Si hace clic en
          un enlace de un tercero, será dirigido a ese sitio. Tenga en cuenta
          que estos sitios externos no son operados por nuestra compañía. Por lo
          tanto, le recomiendo que revise la Política de privacidad de estos
          sitios web. No tenemos control, ni asumimos ninguna responsabilidad
          por el contenido, las políticas de privacidad o las prácticas de
          sitios o servicios de terceros.
        </p>
        <p><b>Privacidad de los niños</b></p>
        Estos Servicios no se
        <p>
          dirigen a ninguna persona menor de 13 años. No recopilo a sabiendas,
          información de identificación personal de niños menores de 13 años. En
          el caso de que descubra que un niño menor de 13 años me ha
          proporcionado información personal, la borro inmediatamente de
          nuestros servidores. Si usted es padre o tutor y sabe que su hijo nos
          ha proporcionado información personal, comuníquese con nosotros, para
          que pueda realizar las acciones necesarias.
        </p>
        <p><b>¿Qué hacemos con los datos que recolectamos?</b></p>
        <p>Los datos recolectados son de dos tipos:</p>
        <ul>
          <li>Datos recolectados por nosotros.</li>
          <li>Datos recolectados por terceros.</li>
        </ul>
        <p><b>Datos recolectados por nosotros</b></p>
        <p>
          Estos datos se recolectan para obtener un feedback de los posibles
          requerimientos de nuestros usuarios y así poder mejorar brindando las
          correctas mejoras en cada actualización
        </p>
        <p>
          Los datos son manipulados a nivel de código, luego procesados en
          grandes cantidades, pero en ningún momento accedemos a datos de un
          usuario en particular, pues están encriptados y solo el usuario dueño
          de la información puede acceder a ellos.
        </p>
        <p>
          Datos recolectados por terceros En los datos recolectados por terceros
          están:
        </p>
        <ul>
          <li><a href="https://policies.google.com/privacy">Google Play</a></li>
          <li><a href="https://support.google.com/analytics/answer/6004245?hl=en">Google Analytics</a></li>
          <li><a href="https://support.google.com/admob/answer/6128543?hl=en">Google Admob</a></li>
          <li><a href="https://firebase.google.com/support/privacy/?hl=es-419">Firebase</a></li>
        </ul>
        <p>
          Puedes dar click a cada uno de los elementos de la lista para acceder
          a sus políticas de datos.
        </p>
      </div>
    </div>
    <footer />
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";

export default {
  name: "Home",
  components: {
    Preloader,
    Menu,
    Footer,
  },
};
</script>
<style scoped>
.content_comming_soon {
  position: absolute;
}

.comming_soon {
  width: 250px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 2000;
}

@media screen and (max-width: 500px) {
  .comming_soon {
    width: 150px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.animate__animated {
  border-radius: 10px;
}

#dos {
  background-image: url("../assets/img/gestionderiesgosop_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  /* background-image: center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

#cuatro {
  background-image: url("../assets/img/gestiondemantenimiento_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

#seis {
  background-image: url("../assets/img/capacitacion_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  /* background-image: center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

#ocho {
  background-image: url("../assets/img/tirecheck_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  /* background-image: center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.clip-back {
  position: absolute;
  width: 100%;
  height: inherit;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .clip-back {
    opacity: 0.6;
  }
}

.benefits__title {
  font-size: 20px;
  text-align: center;
  font-weight: 800;
}

.benefits {
  background-image: url("../assets/img/back.svg");
  background-position: center;
  display: grid;
  align-items: center;
  padding: 90px 100px;
  border-top: 0.5px solid #e4e4e4;
  min-height: 100vh;
  overflow: auto;
}

.benefits__text {
  font-size: 16px;
  margin-bottom: 0;
  color: #131313;
  text-align: justify;
  text-justify: inter-word;
}

.features__icon {
  font-size: 30px;
  font-weight: 300;
  color: var(--primary);
  width: 40px;
}

.features__icon--white {
  color: var(--white);
}

.features__tire {
  width: 30px;
  margin-right: 15px;
  padding-bottom: 5px;
}
</style>
